import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import CustomHooks from "../../../@components/hooks";
import { useTranslation } from "react-i18next";
import Lottie from "lottie-react";
import successAnimation from "../../../static/success_animated2.json";

const Index = ({ applyCustomStyles, DAO, collection }) => {
  const { t } = useTranslation();
  let query = CustomHooks.useQuery();
  const redirectUrl = query.get("redirectUrl");
  const [customStyles, setCustomStyles] = React.useState(applyCustomStyles);
  const successAnimationRef = React.useRef(null);
  const source = localStorage.getItem("source");

  const [counter, setCounter] = React.useState(5);

  useEffect(() => {
    const timer =
      counter > 0
        ? setInterval(() => {
            setCounter(counter - 1);
          }, 1000)
        : handleRedirect();

    return () => clearInterval(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counter]);

  React.useEffect(() => {
    if (source === "qvrse") {
      setCustomStyles(false);
    }
  }, [source]);

  function appendQueryParam(url, param) {
    if (!url) return url;
    // Check if URL already has query parameters
    const separator = url.includes("?") ? "&" : "?";
    return `${url}${separator}${param}`;
  }

  const handleRedirect = () => {
    const orderId = localStorage.getItem("order_id");
    //When buying with stripe, check for redirect url from localstorage
    const redirectUrlFromLocalStorage = localStorage.getItem("redirectUrl");

    localStorage.removeItem("redirectUrl");
    localStorage.removeItem("source");
    localStorage.removeItem("access_token");
    localStorage.removeItem("local_storage_manual_selected_nft");
    if (redirectUrl) {
      const url = appendQueryParam(
        redirectUrl,
        `qvrsePayment=success&orderId=${orderId}`
      );
      window.location.href = url;
    } else if (redirectUrlFromLocalStorage) {
      // When buying with stripe, redirect url is stored in localstorage
      const url = appendQueryParam(
        redirectUrlFromLocalStorage,
        `qvrsePayment=success&orderId=${orderId}`
      );
      window.location.href = url;
    } else {
      window.location.href =
        process.env.NODE_ENV === "development"
          ? "http://localhost:3000"
          : window.env.CHAIN === "MUMBAI"
          ? "https://mumbai.qvrse.io/"
          : "https://qvrse.io/";
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",

          width: { md: "370px", mobile: "100%" },
        }}
      >
        <MobileHeader
          customStyles={customStyles}
          DAO={DAO}
          sx={{ mb: "20px !important" }}
        />
        <Lottie
          lottieRef={successAnimationRef}
          autoplay={true}
          loop={false}
          style={{
            width: "100%",
          }}
          animationData={successAnimation}
        />
        <Typography
          variant={"h2"}
          sx={{
            fontSize: "22px !important",
            color: "#000",
            textAlign: "center",
            lineHeight: "31px",
          }}
        >
          {t("success.redirecting", { counter })}
        </Typography>
        <Typography
          variant={"subtitle2"}
          sx={{
            mt: "18px",
            mb: "42px",
            fontFamily: "Open Sans",
            fontSize: "14px !important",
            color: "#000",
            textAlign: "center",
          }}
        >
          {t("success.redirecting2")}{" "}
          <Box
            sx={{
              color: "blue",
              cursor: "pointer",
              textDecoration: "underline",
            }}
            component={"span"}
            onClick={() => handleRedirect()}
          >
            {t("commons.here")}
          </Box>
        </Typography>

        {/*<Box*/}
        {/*  sx={{*/}
        {/*    mt: { mobile: "30px", md: "0px" },*/}
        {/*    width: { md: "370px", mobile: "unset" },*/}
        {/*    px: { mobile: "20px", md: "0px" },*/}
        {/*  }}*/}
        {/*>*/}
        {/*  <Typography*/}
        {/*    variant={"h2"}*/}
        {/*    sx={{*/}
        {/*      fontSize: "22px !important",*/}
        {/*      color: "#000",*/}
        {/*      textAlign: "center",*/}
        {/*      lineHeight: "31px",*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    {t("success.title")}*/}
        {/*  </Typography>*/}
        {/*  {collection?.total_price_with_tax !== "0.00" && (*/}
        {/*    <Typography*/}
        {/*      variant={"subtitle2"}*/}
        {/*      sx={{*/}
        {/*        mt: "18px",*/}
        {/*        mb: "42px",*/}
        {/*        fontFamily: "Open Sans",*/}
        {/*        fontSize: "14px !important",*/}
        {/*        color: "#000",*/}
        {/*        textAlign: "center",*/}
        {/*      }}*/}
        {/*    >*/}
        {/*      {t("success.subtitle")}*/}
        {/*    </Typography>*/}
        {/*  )}*/}
        {/*  {collection?.total_price_with_tax === "0.00" && (*/}
        {/*    <Typography*/}
        {/*      variant={"subtitle2"}*/}
        {/*      sx={{*/}
        {/*        mt: "18px",*/}
        {/*        mb: "42px",*/}
        {/*        fontFamily: "Open Sans",*/}
        {/*        fontSize: "14px !important",*/}
        {/*        color: "#000",*/}
        {/*        textAlign: "center",*/}
        {/*      }}*/}
        {/*    >*/}
        {/*      {t("success.subtitle2")}*/}
        {/*    </Typography>*/}
        {/*  )}*/}
        {/*</Box>*/}
        {/*<UI.Button*/}
        {/*  type={"primary"}*/}
        {/*  title={t("success.button")}*/}
        {/*  sx={{*/}
        {/*    margin: "0 auto",*/}
        {/*    width: "90%",*/}
        {/*    color: customStyles ? "#000" : "#fff",*/}
        {/*    backgroundColor: customStyles*/}
        {/*      ? `${DAO?.dao_style[0]?.data.background_color}`*/}
        {/*      : "#000",*/}
        {/*    "&:hover": {*/}
        {/*      backgroundColor: customStyles*/}
        {/*        ? `${DAO?.dao_style[0]?.data.background_color}`*/}
        {/*        : "#944DFF",*/}
        {/*      border: customStyles*/}
        {/*        ? `0px solid ${DAO?.dao_style[0]?.data.background_color}`*/}
        {/*        : "0px solid #944DFF",*/}
        {/*    },*/}
        {/*  }}*/}
        {/*  onClick={() => {*/}
        {/*    const orderId = localStorage.getItem("order_id");*/}
        {/*    //When buying with stripe, check for redirect url from localstorage*/}
        {/*    const redirectUrlFromLocalStorage =*/}
        {/*      localStorage.getItem("redirectUrl");*/}

        {/*    localStorage.removeItem("redirectUrl");*/}
        {/*    localStorage.removeItem("source");*/}
        {/*    localStorage.removeItem("access_token");*/}
        {/*    localStorage.removeItem("local_storage_manual_selected_nft");*/}
        {/*    if (redirectUrl) {*/}
        {/*      const url = appendQueryParam(*/}
        {/*        redirectUrl,*/}
        {/*        `qvrsePayment=success&orderId=${orderId}`*/}
        {/*      );*/}
        {/*      window.location.href = url;*/}
        {/*    } else if (redirectUrlFromLocalStorage) {*/}
        {/*      // When buying with stripe, redirect url is stored in localstorage*/}
        {/*      const url = appendQueryParam(*/}
        {/*        redirectUrlFromLocalStorage,*/}
        {/*        `qvrsePayment=success&orderId=${orderId}`*/}
        {/*      );*/}
        {/*      window.location.href = url;*/}
        {/*    } else {*/}
        {/*      window.location.href =*/}
        {/*        process.env.NODE_ENV === "development"*/}
        {/*          ? "http://localhost:3000"*/}
        {/*          : window.env.CHAIN === "MUMBAI"*/}
        {/*          ? "https://mumbai.qvrse.io/"*/}
        {/*          : "https://qvrse.io/";*/}
        {/*    }*/}
        {/*  }}*/}
        {/*/>*/}
      </Box>
    </>
  );
};

const MobileHeader = ({ customStyles, DAO }) => {
  return (
    <Box
      sx={{
        backgroundColor: customStyles
          ? DAO?.dao_style[0]?.data?.card_background_color
          : "#000",
        height: "50px",
        display: { mobile: "flex", md: "none" },
        alignItems: "center",
        position: "relative",
      }}
    >
      <Typography
        variant={"subtitle1"}
        sx={{
          color: "#fff",
          fontSize: "12px !important",
          position: "absolute",
          left: "50%",
          fontWeight: "bold",
          transform: "translateX(-50%)",
        }}
      >
        SUCCESS
      </Typography>
    </Box>
  );
};

export default Index;
