import React from "react";
import Register from "./register";
import RequestInfo from "./requestInfo";
import Payment from "./payment";
import Success from "./success";
import FormProvider from "./context";
import { Box } from "@mui/material";
import CustomHooks from "../../@components/hooks";

const Index = ({
  DAO,
  applyCustomStyles,
  collection,
  activeStep,
  setActiveStep,
  hasRequestInfoFields,
  onActiveStepChange,
}) => {
  let query = CustomHooks.useQuery();
  const loggedInUserEmail = query.get("email");
  const redirectUrl = query.get("redirectUrl");

  const registerWithRequestInfoFlow = (step) => {
    switch (step) {
      case 0:
        onActiveStepChange("register");
        return (
          <Register
            applyCustomStyles={applyCustomStyles}
            DAO={DAO}
            collection={collection}
            hasRequestInfoFields={hasRequestInfoFields}
            nextStep={() => {
              if (hasRequestInfoFields) {
                setActiveStep(activeStep + 1);
                window.scrollTo(0, 0);
              } else {
                setActiveStep(activeStep + 2);
                window.scrollTo(0, 0);
              }
            }}
          />
        );
      case 1:
        onActiveStepChange("request_info");
        return (
          <RequestInfo
            applyCustomStyles={applyCustomStyles}
            collection={collection}
            DAO={DAO}
            previouseStep={() => {
              setActiveStep(activeStep - 1);
              window.scrollTo(0, 0);
            }}
            nextStep={() => {
              setActiveStep(activeStep + 1);
              window.scrollTo(0, 0);
            }}
          />
        );
      case 2:
        onActiveStepChange("payment");
        return (
          <Payment
            applyCustomStyles={applyCustomStyles}
            collection={collection}
            DAO={DAO}
            currentStep={activeStep}
            previouseStep={() => {
              setActiveStep(activeStep - 1);
              window.scrollTo(0, 0);
            }}
            nextStep={() => {
              setActiveStep(activeStep + 1);
              window.scrollTo(0, 0);
            }}
          />
        );
      case 3:
        onActiveStepChange("success");
        return (
          <Success
            applyCustomStyles={applyCustomStyles}
            collection={collection}
            DAO={DAO}
          />
        );
      default:
        return null;
    }
  };
  const registerWithoutRequestInfoFlow = (step) => {
    switch (step) {
      case 0:
        onActiveStepChange("register");
        return (
          <Register
            applyCustomStyles={applyCustomStyles}
            DAO={DAO}
            collection={collection}
            hasRequestInfoFields={hasRequestInfoFields}
            nextStep={() => {
              setActiveStep(activeStep + 1);
              window.scrollTo(0, 0);
            }}
          />
        );
      case 1:
        onActiveStepChange("payment");
        return (
          <Payment
            applyCustomStyles={applyCustomStyles}
            collection={collection}
            DAO={DAO}
            currentStep={activeStep}
            previouseStep={() => {
              setActiveStep(activeStep - 1);
              window.scrollTo(0, 0);
            }}
            nextStep={() => {
              setActiveStep(activeStep + 1);
              window.scrollTo(0, 0);
            }}
          />
        );
      case 2:
        onActiveStepChange("success");
        return (
          <Success
            applyCustomStyles={applyCustomStyles}
            collection={collection}
            DAO={DAO}
          />
        );
      default:
        return null;
    }
  };

  const registerWithoutRequestInfoWithAutoLoginFlow = (step) => {
    switch (step) {
      case 0:
        onActiveStepChange("payment");
        return (
          <Payment
            hasRequestInfoFields={hasRequestInfoFields}
            applyCustomStyles={applyCustomStyles}
            loggedInUserEmail={loggedInUserEmail}
            autoLogin={true}
            collection={collection}
            DAO={DAO}
            currentStep={activeStep}
            previouseStep={() => {
              setActiveStep(activeStep - 1);
              window.scrollTo(0, 0);
            }}
            nextStep={() => {
              setActiveStep(activeStep + 1);
              window.scrollTo(0, 0);
            }}
          />
        );
      case 1:
        onActiveStepChange("success");
        return (
          <Success
            applyCustomStyles={applyCustomStyles}
            collection={collection}
            DAO={DAO}
          />
        );
      default:
        return null;
    }
  };

  const registerWithRequestInfoWithAutoLoginFlow = (step) => {
    switch (step) {
      case 0:
        onActiveStepChange("request_info");
        return (
          <RequestInfo
            autoLogin={true}
            applyCustomStyles={applyCustomStyles}
            collection={collection}
            DAO={DAO}
            previouseStep={() => {
              window.location.href = redirectUrl;
            }}
            nextStep={() => {
              setActiveStep(activeStep + 1);
              window.scrollTo(0, 0);
            }}
          />
        );
      case 1:
        onActiveStepChange("payment");
        return (
          <Payment
            applyCustomStyles={applyCustomStyles}
            collection={collection}
            DAO={DAO}
            currentStep={activeStep}
            previouseStep={() => {
              setActiveStep(activeStep - 1);
              window.scrollTo(0, 0);
            }}
            nextStep={() => {
              setActiveStep(activeStep + 1);
              window.scrollTo(0, 0);
            }}
          />
        );
      case 2:
        onActiveStepChange("success");
        return (
          <Success
            applyCustomStyles={applyCustomStyles}
            collection={collection}
            DAO={DAO}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <FormProvider>
        {
          //Flow WITH request info fields and user is NOT logged in
        }
        {hasRequestInfoFields &&
          !loggedInUserEmail &&
          registerWithRequestInfoFlow(activeStep)}
        {
          //Flow with NO request info fields and user is NOT logged in
        }
        {!hasRequestInfoFields &&
          !loggedInUserEmail &&
          registerWithoutRequestInfoFlow(activeStep)}
        {
          //Flow with NO request info fields and user IS logged in
        }
        {!hasRequestInfoFields &&
          loggedInUserEmail &&
          registerWithoutRequestInfoWithAutoLoginFlow(activeStep)}
        {
          //Flow WITH request info fields and user IS logged in
        }
        {hasRequestInfoFields &&
          loggedInUserEmail &&
          registerWithRequestInfoWithAutoLoginFlow(activeStep)}
      </FormProvider>
    </Box>
  );
};

export default Index;
