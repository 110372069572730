import React from "react";
import { Box, Typography } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CustomHooks from "../@components/hooks";
import { useTranslation } from "react-i18next";

const BackButton = ({
  source,
  activeStep,
  setActiveStep,
  activeStepName,
  hasRequestInfoFields,
  loggedInUserEmail,
}) => {
  const { t } = useTranslation();
  let query = CustomHooks.useQuery();
  const redirectUrl = query.get("redirectUrl");
  return (
    <Box
      onClick={() => {
        if (activeStep === 0) {
          localStorage.removeItem("source");
          localStorage.removeItem("local_storage_manual_selected_nft");
          window.location.href = redirectUrl;
        } else {
          setActiveStep(activeStep - 1);
        }
      }}
      sx={{
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
      }}
    >
      <ArrowBackIosIcon sx={{ color: "rgba(0, 0, 0, 0.4);", height: "1rem" }} />
      <Typography
        variant={"subtitle1"}
        sx={{
          color: "rgba(0, 0, 0, 0.4);",
          fontSize: "12px !important",
          fontWeight: "bold",
        }}
      >
        {getBackButtonTitle(
          t,
          source,
          activeStepName,
          activeStep,
          hasRequestInfoFields,
          loggedInUserEmail
        )}
      </Typography>
    </Box>
  );
};

const getBackButtonTitle = (
  t,
  source,
  activeStepName,
  activeStep,
  hasRequestInfoFields,
  loggedInUserEmail
) => {
  switch (activeStepName) {
    case "register":
      return source === "qvrse"
        ? t("backButton.backToQvrse")
        : t("backButton.back");
    case "destination":
      return hasRequestInfoFields
        ? t("backButton.requestedInfo")
        : loggedInUserEmail
        ? source === "qvrse"
          ? t("backButton.backToQvrse")
          : t("backButton.back")
        : !loggedInUserEmail
        ? t("backButton.back")
        : t("backButton.back");
    case "request_info":
      return loggedInUserEmail
        ? source === "qvrse"
          ? t("backButton.backToQvrse")
          : t("backButton.back")
        : !loggedInUserEmail
        ? t("backButton.back")
        : t("backButton.back");
    case "payment":
      return loggedInUserEmail
        ? source === "qvrse"
          ? t("backButton.backToQvrse")
          : t("backButton.back")
        : !loggedInUserEmail
        ? t("backButton.back")
        : t("backButton.back");
    default:
      return null;
  }
};

export default BackButton;
