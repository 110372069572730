import React from "react";
import Fiat from "./fiat";
import Crypto from "./crypto";
import CustomHooks from "../../../@components/hooks";
import { useFormData } from "../context";
import useService from "../../useService";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

const Index = ({
  applyCustomStyles,
  DAO,
  collection,
  previouseStep,
  nextStep,
  currentStep,
  loggedInUserEmail,
  hasRequestInfoFields,
}) => {
  const { t } = useTranslation();
  const { setFormValues: setFormData } = useFormData();
  const service = useService();
  const { enqueueSnackbar } = useSnackbar();
  const [, setHasErrorsOnRegister] = React.useState(false);
  let query = CustomHooks.useQuery();
  const selectedNFT = query.get("selectedNFT");
  const reservedSeats = query.get("selectedSeats");

  const { mutate: registerUser } = CustomHooks.usePost(service.registerUser, {
    onSuccess: async (response) => {
      const { access, user } = response;
      const { id: userId, smart_address: walletAddress } = user;

      setFormData({
        userId: userId,
        wallet_address: walletAddress,
        nft_destination_address: walletAddress,
      });
      localStorage.setItem("access_token", access);

      // Create order if there are no request info fields
      if (hasRequestInfoFields != null && !hasRequestInfoFields) {
        await newOrder({
          dao_id: DAO.id,
          ...(selectedNFT
            ? { manual_selected_nft_image_id: parseInt(selectedNFT) }
            : {}),
          collection_id: collection.id,
          data: {
            ...(selectedNFT
              ? { manual_selected_nft_image_id: parseInt(selectedNFT) }
              : {}),
            collection_id: collection.id,
            ...(reservedSeats
              ? { reserved_seats: reservedSeats.split(",") } // Add reserved seats
              : {}),
          },
          mint_to_vault: false,
        });
      }
    },
    onError: (error) => {
      enqueueSnackbar(
        error?.response.data.message || t("commons.somethingWentWrong"),
        {
          variant: "error",
        }
      );
      setHasErrorsOnRegister(true);
    },
  });

  const { mutate: newOrder } = CustomHooks.usePost(service.createOrder, {
    onSuccess: (response) => {
      const { id: orderId } = response;
      setFormData({ order_id: orderId });
      localStorage.setItem("order_id", orderId);
    },
    onError: (error) => {
      enqueueSnackbar(
        error?.response.data.message || t("commons.somethingWentWrong"),
        {
          variant: "error",
        }
      );
      setHasErrorsOnRegister(true);
    },
  });

  React.useEffect(() => {
    const fetchUser = async () => {
      if (
        loggedInUserEmail &&
        registerUser &&
        DAO &&
        collection &&
        hasRequestInfoFields != null
      ) {
        await logInSignedInUser(loggedInUserEmail);
      }
    };
    fetchUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registerUser, loggedInUserEmail, DAO, collection, hasRequestInfoFields]);

  const logInSignedInUser = async (email) => {
    setFormData({ user_email: email });
    await registerUser(email);
  };

  return (
    <>
      {collection?.currency === "EUR" ? (
        <Fiat
          applyCustomStyles={applyCustomStyles}
          DAO={DAO}
          currentStep={currentStep}
          collection={collection}
          nextStep={nextStep}
          previouseStep={previouseStep}
        />
      ) : (
        <Crypto
          applyCustomStyles={applyCustomStyles}
          DAO={DAO}
          collection={collection}
          previouseStep={previouseStep}
          nextStep={nextStep}
        />
      )}
    </>
  );
};

export default Index;
