import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import "@rainbow-me/rainbowkit/styles.css";
import {
  getDefaultWallets,
  RainbowKitProvider,
  darkTheme,
} from "@rainbow-me/rainbowkit";
import { ThemeProvider } from "@mui/material/styles";
import Themes from "./@components/designSystem/themes";
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import { jsonRpcProvider } from "wagmi/providers/jsonRpc";
import { hardhat, polygonAmoy, polygon } from "wagmi/chains";
import { infuraProvider } from "wagmi/providers/infura";
import { BrowserRouter, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import PaymentFlow from "./paymentFlow";
import { SnackbarProvider } from "notistack";
import qvrseLogo from "./static/qvrse-logo.webp";
import { Box } from "@mui/material";
import "./i18n";
import { useTranslation } from "react-i18next";
import CustomHooks from "./@components/hooks";
import AlchemyWrapper from "./AlchemyWrapper";
import UseExternalBrowserDialog from "./UseExternalBrowserDialog";
import useMediaQuery from "@mui/material/useMediaQuery";

const isInstagramInAppBrowser = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  return userAgent.includes("Instagram");
};

const getChainsForEnvironment = () => {
  if (window.env.CHAIN === "POLYGON") {
    console.log("POLYGON chain");
    return [polygon];
  } else if (window.env.CHAIN === "AMOY") {
    console.log("AMOY chain");
    return [polygonAmoy];
  } else {
    console.log("Return utilities chains");
    return [hardhat, polygonAmoy, polygon];
  }
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // default: true
    },
  },
});

const { chains, publicClient } = configureChains(
  getChainsForEnvironment(),
  [
    infuraProvider({
      apiKey:
        window.env.CHAIN === "MUMBAI"
          ? "f30ebb23539f4adbbf2e7f9568af5af2"
          : "ed8f41cd961b407ca7310eec767be5a7",
    }),
    jsonRpcProvider({
      rpc: (chain) => ({
        http: `https://rpc-amoy.polygon.technology/`,
      }),
    }),
  ],

  {
    pollingInterval: 10000,
    stallTimeout: 10000,
    retryCount: 5,
    retryDelay: 2000,
  }
);

const { connectors } = getDefaultWallets({
  appName: "Qvrse Payments",
  projectId: "baab8c3625087d0dadd45a3c5b5c815e",
  chains,
});

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
});

const Index = () => {
  let query = CustomHooks.useQuery();
  const lng = query.get("lng");
  const { i18n } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const [isInstagramBrowser, setIsInstagramBrowser] = React.useState(false);

  React.useEffect(() => {
    setIsInstagramBrowser(isInstagramInAppBrowser());
  }, []);

  React.useEffect(() => {
    if (lng && i18n) {
      i18n.changeLanguage(lng);
    }
  }, [lng, i18n]);

  return (
    <>
      <Route
        path="/brand/:brandId/collection/:collectionId"
        component={PaymentFlow}
      />
      {isInstagramBrowser && isMobile && <UseExternalBrowserDialog />}
    </>
  );
};

ReactDOM.render(
  <Suspense
    fallback={
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          textAlign: "center",
        }}
      >
        <Box component={"img"} src={qvrseLogo} alt={"qvrse-logo"} />
      </Box>
    }
  >
    <BrowserRouter basename={""}>
      <WagmiConfig config={wagmiConfig}>
        <RainbowKitProvider
          chains={chains}
          theme={darkTheme({
            accentColor: "#944DFF",
            accentColorForeground: "white",
            borderRadius: "none",
            fontStack: "system",
            overlayBlur: "small",
          })}
        >
          <QueryClientProvider client={queryClient}>
            <ThemeProvider theme={Themes.Dark}>
              <SnackbarProvider
                preventDuplicate
                maxSnack={3}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                autoHideDuration={5000}
              >
                <AlchemyWrapper queryClient={queryClient}>
                  <Index />
                </AlchemyWrapper>
              </SnackbarProvider>
            </ThemeProvider>
          </QueryClientProvider>
        </RainbowKitProvider>
      </WagmiConfig>
    </BrowserRouter>
  </Suspense>,
  document.getElementById("root")
);

reportWebVitals();
