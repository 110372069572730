import React from "react";
import UI from "../../../../../@components/UI";
import CustomHooks from "../../../../../@components/hooks";
import useService from "./useService";

const Button = (props) => {
  const {
    title,
    onError,
    orderId,
    DAO,
    onSuccess,
    applyCustomStyles,
    collection,
    ...other
  } = props;
  const service = useService();
  const [isLoading, setIsLoading] = React.useState(false);

  const { mutate: create } = CustomHooks.usePost(service.create, {
    onSuccess: async () => {
      setIsLoading(false);
      onSuccess();
    },
  });

  return (
    <>
      <UI.Busy.FullscreenIndicator show={isLoading} />

      <UI.Button
        disabled={isLoading}
        onClick={() => {
          setIsLoading(true);
          create({
            orderId,
            collectionId: collection.id,
            daoId: collection.dao_id,
          });
        }}
        sx={{
          color: applyCustomStyles ? "#000" : "#fff",
          backgroundColor: applyCustomStyles
            ? `${DAO?.dao_style[0]?.data.background_color}`
            : "#000",
          "&:hover": {
            backgroundColor: applyCustomStyles
              ? `${DAO?.dao_style[0]?.data.background_color}`
              : "#944DFF",
            border: applyCustomStyles
              ? `0px solid ${DAO?.dao_style[0]?.data.background_color}`
              : "0px solid #944DFF",
          },
        }}
        type={"primary"}
        title={title}
        {...other}
      />
    </>
  );
};

export default Button;
