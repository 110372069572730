import Gateway from "../@components/gateway";
import { useTranslation } from "react-i18next";

const useService = () => {
  const gateway = Gateway.useGateway();
  const { i18n } = useTranslation();
  const dao = async (daoId) => {
    const response = await gateway.get(`/dao/${daoId}/`);
    return response.data;
  };

  const registerUser = async (email) => {
    const response = await gateway.postForm(
      `/check_for_payment_user/`,
      {
        email,
      },
      {
        headers: {
          "Accept-Language": i18n.language,
        },
      }
    );
    return response.data;
  };

  const collection = async (daoId, id) => {
    const response = await gateway.get(`dao/${daoId}/collection/${id}/`);
    return response.data;
  };

  const getNextCollectionImage = async (daoId, id) => {
    const response = await gateway.post(
      `dao/${daoId}/collection/${id}/get_next_ticket_image/`
    );
    return response.data;
  };

  const buyTicket = (id, data) => {
    return gateway.post(
      `dao/${data.dao_id}/collection/${id}/buy_ticket/`,
      data
    );
  };

  const getNFTVaultPassword = (orderId) => {
    return gateway.post(`get_nft_vault_password/`, {
      order_id: orderId,
    });
  };

  const createOrder = async (data) => {
    const response = await gateway.post(
      `dao/${data.dao_id}/collection/${data.collection_id}/order/new/`,
      data.data
    );
    return response.data;
  };

  const updateOrder = async (data) => {
    const response = await gateway.post(
      `dao/${data.dao_id}/collection/${data.collection_id}/order/${data.order_id}/make_update/`,
      data.data
    );
    return response.data;
  };

  const checkUser = async (searchString) => {
    const response = await gateway.post(`search_user_match/`, {
      search: searchString,
    });
    return response.data;
  };

  const getVaultContract = async () => {
    const response = await gateway.get(`contracts/nft_vault`);
    return response.data;
  };

  return {
    getNFTVaultPassword,
    buyTicket,
    createOrder,
    updateOrder,
    registerUser,
    checkUser,
    dao,
    collection,
    getNextCollectionImage,
    getVaultContract,
  };
};

export default useService;
