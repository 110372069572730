import React from "react";
import { Box, Typography } from "@mui/material";
import UI from "../../../@components/UI";
import { useForm, Controller } from "react-hook-form";
import CustomHooks from "../../../@components/hooks";
import { Alert } from "@mui/lab";
import { useFormData } from "../context";
import useService from "../../useService";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useTranslation } from "react-i18next";
import CreateSmartAccountDialog from "./CreateSmartAccountDialog";

const Index = ({
  applyCustomStyles,
  DAO,
  collection,
  nextStep,
  hasRequestInfoFields,
}) => {
  let query = CustomHooks.useQuery();
  const { t } = useTranslation();
  const service = useService();
  const [registeredUser, setRegisteredUser] = React.useState(null);
  const { data: formData, setFormValues: setFormData } = useFormData();
  const loggedInUserEmail = query.get("email");
  const selectedNFT = query.get("selectedNFT");
  const reservedSeats = query.get("selectedSeats");
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: formData?.user_email || "",
    },
    mode: "onSubmit",
  });

  const { mutate: registerUser } = CustomHooks.usePost(service.registerUser, {
    onSuccess: async (response) => {
      const { access, user } = response;
      const { smart_address: walletAddress } = user;
      localStorage.setItem("access_token", access);

      if (!walletAddress) {
        setRegisteredUser(user);
        return;
      }
      setFormData({
        wallet_address: walletAddress,
        nft_destination_address: walletAddress,
      });

      // Create order if there are no request info fields
      if (!hasRequestInfoFields) {
        await newOrder({
          dao_id: DAO.id,
          ...(selectedNFT
            ? { manual_selected_nft_image_id: parseInt(selectedNFT) }
            : {}),
          collection_id: collection.id,
          data: {
            ...(selectedNFT
              ? { manual_selected_nft_image_id: parseInt(selectedNFT) }
              : {}),
            collection_id: collection.id,
            ...(reservedSeats
              ? { reserved_seats: reservedSeats.split(",") } // Add reserved seats
              : {}),
          },
        });
      } else {
        nextStep();
      }
    },
  });

  const { mutate: newOrder } = CustomHooks.usePost(service.createOrder, {
    onSuccess: (response) => {
      const { id: orderId } = response;
      setFormData({ order_id: orderId });
      localStorage.setItem("order_id", orderId);
      nextStep();
    },
  });

  React.useEffect(() => {
    const fetchUser = async () => {
      if (loggedInUserEmail && registerUser && DAO) {
        await logInSignedInUser(loggedInUserEmail);
      }
    };
    fetchUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registerUser, loggedInUserEmail, DAO]);

  const logInSignedInUser = async (email) => {
    setFormData({ user_email: email });
    await registerUser(email);
  };

  const onSubmit = async (form) => {
    const { email } = form;
    setFormData({ user_email: email });
    await registerUser(email);
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",

        width: { md: "370px", mobile: "100%" },
      }}
    >
      <MobileHeader
        applyCustomStyles={applyCustomStyles}
        DAO={DAO}
        loggedInUserEmail={loggedInUserEmail}
      />
      <Box
        sx={{
          mt: { mobile: "30px", md: "0px" },
          width: { md: "370px", mobile: "unset" },
          px: { mobile: "20px", md: "0px" },
        }}
      >
        <Typography
          variant={"h2"}
          sx={{
            fontSize: "22px !important",
            color: "#000",
            textAlign: "center",
          }}
        >
          {t("register.title")}
        </Typography>
        <Typography
          variant={"subtitle1"}
          sx={{
            mt: "18px",
            mb: "30px",
            fontFamily: "Open Sans",
            fontSize: "14px !important",
            color: "#000",
            textAlign: "center",
          }}
        >
          {loggedInUserEmail
            ? t("register.loggedInSubtitle")
            : t("register.loggedOutSubtitle")}
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box>
            <Controller
              name={"email"}
              control={control}
              rules={{
                required: true,
                pattern: {
                  value:
                    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                  message: t("register.email.error.pattern"),
                },
              }}
              render={({ field }) => (
                <UI.Inputs.TextField
                  data-test={"email"}
                  {...field}
                  fullWidth
                  backgroundColor={"#ffffff"}
                  placeholder={t("register.email.placeholder")}
                />
              )}
            />
            <Typography
              sx={{
                fontSize: "14px !important",
                fontWeight: "bold",
              }}>
              {errors.email && (
                <Alert
                  variant="outlined"
                  severity="error"
                >
                  {errors.email?.type === "pattern" && errors.email.message}
                  {errors.email?.type === "required" &&
                    t("register.email.error.required")}
                </Alert>
              )}
            </Typography>
          </Box>

          <UI.Button
            data-test={"continue"}
            type={"primary"}
            sx={{
              mt: "35px",
              color: applyCustomStyles ? "#000" : "#fff",
              backgroundColor: applyCustomStyles
                ? `${DAO?.dao_style[0]?.data.background_color}`
                : "#000",
              "&:hover": {
                backgroundColor: applyCustomStyles
                  ? `${DAO?.dao_style[0]?.data.background_color}`
                  : "#944DFF",
                border: applyCustomStyles
                  ? `0px solid ${DAO?.dao_style[0]?.data.background_color}`
                  : "0px solid #944DFF",
              },
            }}
            title={t("buttons.continue")}
          />
          <Typography
            variant={"subtitle2"}
            sx={{
              fontSize: "11px !important",
              color: "#000",
              fontWeight: "bold",
              lineHeight: "18px",
              mb: "45px",
              mt: "13px",
              textAlign: "center",
              fontFamily: "Open Sans",
            }}
          >
            {t("commons.terms")}
          </Typography>
        </form>
      </Box>
      {registeredUser && (
        <CreateSmartAccountDialog
          toggleOpen={true}
          userEmail={registeredUser?.email}
          onSuccess={async (smart_wallet) => {
            setFormData({
              user_id: registeredUser?.user_id,
              wallet_address: smart_wallet,
              nft_destination_address: smart_wallet,
            });
            setRegisteredUser(null);
            // Create order if there are no request info fields
            if (!hasRequestInfoFields) {
              await newOrder({
                dao_id: DAO.id,
                ...(selectedNFT
                  ? { manual_selected_nft_image_id: parseInt(selectedNFT) }
                  : {}),
                collection_id: collection.id,
                data: {
                  ...(selectedNFT
                    ? { manual_selected_nft_image_id: parseInt(selectedNFT) }
                    : {}),
                  collection_id: collection.id,
                },
              });
            } else {
              nextStep();
            }
          }}
        />
      )}
    </Box>
  );
};

const MobileHeader = ({ applyCustomStyles, DAO, loggedInUserEmail }) => {
  const { t } = useTranslation();
  let query = CustomHooks.useQuery();
  const redirectUrl = query.get("redirectUrl");
  const source = query.get("source");
  const goBack = () => {
    window.location.href = redirectUrl;
  };
  return (
    <Box
      onClick={goBack}
      sx={{
        backgroundColor: applyCustomStyles
          ? DAO?.dao_style[0]?.data?.card_background_color
          : "#000",
        height: "50px",
        display: { mobile: "flex", md: "none" },
        alignItems: "center",
        position: "relative",
      }}
    >
      <ArrowBackIosIcon sx={{ color: "#fff", ml: "20px", height: "20px" }} />
      <Typography
        variant={"subtitle1"}
        sx={{
          color: "#fff",
          fontSize: "12px !important",
          position: "absolute",
          left: "50%",
          fontWeight: "bold",
          transform: "translateX(-50%)",
        }}
      >
        {!loggedInUserEmail && source === "qvrse"
          ? t("backButton.guestPurchase")
          : t("backButton.login")}
      </Typography>
    </Box>
  );
};

export default Index;
