import Tooltip from "@mui/material/Tooltip";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Typography from "@mui/material/Typography";
import React from "react";

const QvrseTooltip = ({ children }) => {
  return (
    <Tooltip
      sx={{
        width: "16px",
        cursor: "pointer",
        ml: "5px",
        color: "000",
      }}
      title={
        <Typography 
          variant={"subtitle1"}
          sx={{
            fontSize: "12px !important",
            opacity: "1 !important",
            lineHeight: "1.4",
            p: "4px 4px 5px",
          }}
        >
          {children}
        </Typography>
      }
      placement="top"
      arrow
    >
      <HelpOutlineIcon />
    </Tooltip>
  );
};

export default QvrseTooltip;
