import React from "react";
import { Box, Typography } from "@mui/material";
import CustomHooks from "../../../@components/hooks";
import UI from "../../../@components/UI";
import { useTranslation } from "react-i18next";

const Index = ({ applyCustomStyles, DAO }) => {
  const { t } = useTranslation();
  let query = CustomHooks.useQuery();
  const redirectUrl = query.get("redirectUrl");
  const error_code = query.get("error_code");
  const [customStyles, setCustomStyles] = React.useState(applyCustomStyles);

  const source = localStorage.getItem("source");

  React.useEffect(() => {
    if (source === "qvrse") {
      setCustomStyles(false);
    }
  }, [source]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",

          width: { md: "370px", mobile: "100%" },
        }}
      >
        <MobileHeader t={t} customStyles={customStyles} DAO={DAO} />
        <Box
          sx={{
            mt: { mobile: "30px", md: "0px" },
            width: { md: "370px", mobile: "unset" },
            px: { mobile: "20px", md: "0px" },
          }}
        >
          <Typography
            variant={"h2"}
            sx={{
              fontSize: "22px !important",
              color: "#000000",
              textAlign: "center",
              lineHeight: "31px",
            }}
          >
            {error_code === "SOLD_OUT" && t("failed.title")}
          </Typography>
          <Typography
            variant={"subtitle2"}
            sx={{
              mt: "18px",
              mb: "42px",
              fontFamily: "Open Sans",
              fontSize: "14px !important",
              color: "#000000",
              textAlign: "center",
            }}
          >
            {t("failed.subtitle")}
          </Typography>
        </Box>
        <UI.Button
          type={"primary"}
          title={t("failed.button")}
          sx={{
            margin: "0 auto",
            width: "90%",
            color: customStyles ? "#000" : "#fff",
            backgroundColor: customStyles
              ? `${DAO?.dao_style[0]?.data.background_color}`
              : "#000000",
            "&:hover": {
              backgroundColor: customStyles
                ? `${DAO?.dao_style[0]?.data.background_color}`
                : "#944DFF",
              border: customStyles
                ? `0px solid ${DAO?.dao_style[0]?.data.background_color}`
                : "0px solid #944DFF",
            },
          }}
          onClick={() => {
            //When buying with stripe, check for redirect url from localstorage
            const redirectUrlFromLocalStorage =
              localStorage.getItem("redirectUrl");
            localStorage.removeItem("redirectUrl");
            localStorage.removeItem("source");
            if (redirectUrl) {
              window.location.href = `${redirectUrl}/?qvrsePayment=failed`;
            } else if (redirectUrlFromLocalStorage) {
              // When buying with stripe, redirect url is stored in localstorage
              window.location.href = `${redirectUrlFromLocalStorage}/?qvrsePayment=failed`;
            }
          }}
        />
      </Box>
    </>
  );
};

const MobileHeader = ({ t, customStyles, DAO }) => {
  return (
    <Box
      sx={{
        backgroundColor: customStyles
          ? DAO?.dao_style[0]?.data?.card_background_color
          : "#000000",
        display: { mobile: "flex", md: "none" },
        alignItems: "center",
        position: "relative",
      }}
    >
      <Typography
        variant={"subtitle1"}
        sx={{
          color: "#fff",
          fontSize: "12px !important",
          position: "absolute",
          left: "50%",
          fontWeight: "bold",
          transform: "translateX(-50%)",
        }}
      >
        {t("failed.mobileHeader")}
      </Typography>
    </Box>
  );
};

export default Index;
