import React, { useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import DialogContent from "@mui/material/DialogContent";
import Box from "@mui/material/Box";
import UI from "../../@components/UI";
import Grid from "@mui/material/Grid";
import MaterialDialog from "@mui/material/Dialog";
import { useTranslation } from "react-i18next";
import Link from "@mui/material/Link";
import secureAccountIcon from "../../static/secure-account.png";
import faceRecognitionIcon from "../../static/face-recognition.png";
import {
  useAuthenticate,
  useLogout,
  useSmartAccountClient,
} from "@alchemy/aa-alchemy/react";
import { useSnackbar } from "notistack";
import { useFormData } from "../../paymentFlow/form/context";

const ConnectToSmartAccount = ({ open, onSuccess, onClose }) => {
  const { data: formData } = useFormData();
  const { logout: smartAccountLogout } = useLogout();
  const { enqueueSnackbar } = useSnackbar();
  const [isAuthenthicating, setIsAuthenticating] = React.useState(false);

  const { isLoadingClient, client } = useSmartAccountClient({
    type: "MultiOwnerModularAccount", // alternatively pass in "LightAccount",
  });

  // Check if the client is loaded and if the account address matches the logged in user's address
  useEffect(() => {
    if (!isLoadingClient && client && isAuthenthicating) {
      if (
        client.account.address.toLowerCase() !==
        formData.wallet_address.toLowerCase()
      ) {
        enqueueSnackbar("Wrong passkey for this account", {
          variant: "error",
        });
        smartAccountLogout();
        onClose();
      } else {
        enqueueSnackbar("Connected to your account", { variant: "success" });
        onSuccess();
      }
      setIsloading(false);
      setIsAuthenticating(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingClient, client, isAuthenthicating]);

  const { authenticate } = useAuthenticate({
    onError: (e) => {
      console.error("Failed to login", e);
      enqueueSnackbar("Failed to login", { variant: "error" });
      setIsloading(false);
      setIsAuthenticating(false);
    },
  });
  const { t } = useTranslation();
  const [isLoading, setIsloading] = React.useState(false);

  return (
    <MaterialDialog
      // TransitionComponent={Transition}
      PaperProps={{
        sx: {
          // Default styles that apply to all breakpoints
          position: "absolute", // Needed to allow for custom positioning
          textAlign: "center",
          borderTopRightRadius: "35px",
          borderTopLeftRadius: "35px",
          background: (theme) => theme.palette.dialog.background,
          backdropFilter: (theme) => theme.palette.dialog.backdropFilter,

          // Styles for mobile and smaller screens
          // This makes the dialog stick to the bottom and occupy 50% of the screen height on mobile devices
          "@media (max-width: 600px)": {
            maxHeight: "auto", // Limits the dialog height to 50% of the viewport height
            width: "100%", // Dialog occupies the full width of the screen
            m: 0, // Removes margin
            mt: "auto", // Pushes the dialog to the bottom of the screen
            bottom: 0, // Aligns to the bottom of the screen
            padding: "28px 10px 30px", // Mobile-specific padding
          },

          // Styles for desktop and larger screens
          // Reverts to the original design on desktops
          "@media (min-width: 600px)": {
            borderRadius: "15px",
            position: "relative", // Use relative positioning on desktop
            background: (theme) => theme.palette.dialog.background,
            backdropFilter: (theme) => theme.palette.dialog.backdropFilter,
            padding: "40px 60px 50px", // Desktop-specific padding
            width: "600px", // Fixed width for desktop
            textAlign: "center",
            margin: "10px", // Margin around the dialog on desktop
          },
        },
      }}
      open={open}
      onClose={onClose}
    >
      <DialogContent sx={{ p: { mobile: "5px 10px 17px", md: "0px" } }}>
        <CloseIcon
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 20,
            top: 30,
            cursor: "pointer",
            "&:hover": {
              transition: "all 0.1s ease 0s",
              transform: "scale(1.1) rotate(-90deg)",
            },
          }}
        />
        <Grid container>
          <Grid item mobile={12} md={12}>
            <Typography variant={"h3"} sx={{ textAlign: "center" }}>
              {t("connectToSmartAccountDialog.title")}
            </Typography>
            <Box
              component={"img"}
              src={secureAccountIcon}
              alt={t("commons.secureAccount")}
              sx={{
                width: "60px",
                margin: "0 auto",
                mt: "20px",
                filter: "invert(1)",
              }}
            />
            <Typography variant={"subtitle2"} sx={{ mt: "16px" }}>
              {t("connectToSmartAccountDialog.subtitle")}
            </Typography>
            <Box
              sx={{
                mt: { md: "34px", mobile: "26px" },
                px: "15px",
                display: "flex",
                fontFamily: "Open Sans !important",
                fontWeight: "400 !important",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <UI.Button
                onClick={() => {
                  setIsloading(true);
                  setIsAuthenticating(true);
                  authenticate({
                    type: "passkey",
                    createNew: false,
                    creationOpts: {
                      publicKey: {
                        rp: {
                          id:
                            process.env.NODE_ENV === "development"
                              ? "localhost"
                              : "qvrse.io",
                          name: "QVRS",
                        },
                        user: {
                          name: formData?.email,
                          displayName: formData?.email,
                        },
                      },
                    },
                  });
                }}
                sx={{ width: "340px" }}
                title={
                  <>
                    <Box
                      component={"img"}
                      src={faceRecognitionIcon}
                      alt={"Face Recognition"}
                      sx={{ width: "20px", filter: "invert(1)", mr: "10px" }}
                    />
                    {t("connectToSmartAccountDialog.button")}
                  </>
                }
                type={"primary"}
              />
              <Box sx={{ mt: { mobile: "10px", md: "10px" }, px: "10px" }}>
                <Typography variant={"subtitle3"} sx={{ textAlign: "center" }}>
                  {t("wrongAccountDialog.support")}{" "}
                  <Link
                    sx={{
                      cursor: "pointer",
                      textDecoration: "underline",
                      transition: "all 0.2s ease-in-out",
                      color: (theme) => theme.palette.text.link,
                      "&:hover": {
                        color: (theme) => theme.palette.text.linkHover,
                      },
                    }}
                    target="_blank"
                    href={"mailto: mainUtility@qvrse.io"}
                  >
                    info@qvrse.io
                  </Link>
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      <UI.Busy.FullscreenIndicator show={isLoading}>
        <Typography variant={"subtitle1"}>
          {"Connecting to your account"}
        </Typography>
      </UI.Busy.FullscreenIndicator>
    </MaterialDialog>
  );
};

export default ConnectToSmartAccount;
