import useFetch from "./useFetch";
import usePost from "./usePost";
import useQuery from "./useQuery";
import useHandleErrors from "./useHandleErrors";
import { useEthersSigner } from "./ethers/useEthersSigner";
import { useEthersProvider } from "./ethers/useEthersProvider";

const CustomHooks = {
  useFetch,
  usePost,
  useQuery,
  useHandleErrors,
  useEthersSigner,
  useEthersProvider,
};

export default CustomHooks;
