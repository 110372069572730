import axios from "axios";
import { useTranslation } from "react-i18next";

const gateway = window.env.GATEWAY;

const useAuth = () => {
  const { i18n } = useTranslation();

  const addSmartAccountToExistingUser = (data) => {
    return new Promise(async (resolve, reject) => {
      const access_token = localStorage.getItem("access_token");

      if (access_token != null) {
        axios
          .post(`${gateway}/set_user_smart_address`, data, {
            headers: {
              Authorization: `Bearer ${access_token}`,
              "Content-Type": "application/json",
              "Accept-Language": i18n.language,
            },
          })
          .then(async (res) => {
            const { access, refresh } = res.data;
            access && localStorage.setItem("access_token", access);
            refresh && localStorage.setItem("refresh_token", refresh);
            resolve(res.data);
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        reject();
      }
    });
  };

  return {
    addSmartAccountToExistingUser,
  };
};

export default useAuth;
