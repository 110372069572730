import { createTheme } from "@mui/material/styles";

const palette = {
  text: {
    primary: "rgba(255, 255, 255, 1)",
    secondary: "rgba(255, 255, 255, 0.8)",
  },
  color: {
    primary: "#944DFF",
    secondary: "#333645",
    darkBlue: "#0F1224",
    darkGray: "#1B1E2F",
    mediumGray: "#141728",
    lightGray: "#272A3A",
    darkRed: "#ef5350",
  },
};

const Light = createTheme({
  typography: {
    fontFamily: "Quantico, Open Sans",
    fontStyle: "normal",
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    h1: {
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "40px",
      lineHeight: "57px",
      textTransform: "uppercase",
      color: palette.text.primary,
    },
    h2: {
      fontWeight: "700",
      fontSize: "24px",
      lineHeight: "34px",
      textTransform: "uppercase",
      color: palette.text.primary,
    },
    h3: {
      fontWeight: "700",
      fontSize: "22px",
      lineHeight: "34px",
      textTransform: "uppercase",
      color: palette.text.primary,
    },
    subtitle1: {
      fontFamily: "Open Sans",
      fontWeight: "400",
      fontSize: "18px",
      lineHeight: "23px",
      color: palette.text.secondary,
      opacity: 0.8,
    },
    subtitle2: {
      fontFamily: "Quantico",
      fontWeight: "400",
      fontSize: "18px",
      lineHeight: "23px",
      color: palette.text.primary,
    },
  },
  palette: {
    colors: {
      primary: palette.color.primary,
      secondary: palette.color.secondary,
      darkBlue: palette.color.darkBlue,
      darkGray: palette.color.darkGray,
      lightGray: palette.color.lightGray,
      darkRed: palette.color.darkRed,
    },
    background: {
      default: "#000",
    },
    text: {
      primary: palette.text.primary,
      secondary: palette.text.secondary,
    },
    appbar: {
      text: {
        primary: palette.text.secondary,
      },
      background: palette.color.darkBlue,
    },
    buttons: {
      primary: palette.color.primary,
      secondary: palette.color.secondary,
      terciary: "transparent",
    },
  },
  components: {
    MuiListItemText: {
      styleOverrides: {
        root: {
          color: palette.text.secondary,
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: palette.text.secondary + "!important",
          minWidth: "unset !important",
          "&:hover": {
            color: palette.text.primary + "!important",
          },
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          display: "flex",
          flexDirection: "column",
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            color: palette.text.primary,
            backgroundColor: "#1B1E2F !important",
            "& .MuiListItemText-primary, & .MuiListItemIcon-root": {
              color: palette.text.primary,
            },
          },
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          "&. Mui-selected, &&. Mui-selected:hover": {
            color: palette.text.primary,
            backgroundColor: palette.color.darkGray,
            "& .MuiListItemText-primary, & .MuiListItemIcon-root": {
              color: palette.text.primary,
            },
          },
          "& .MuiListItemButton-root:hover": {
            color: palette.text.primary,
            backgroundColor: palette.color.darkGray,
            "& .MuiListItemText-primary": {
              color: palette.text.primary,
            },
            "& .MuiListItemIcon-root": {
              color: palette.text.primary,
            },
          },
          "& .MuiListItemButton-root": {
            height: "97px",
            justifyContent: "center",
          },
          "& .MuiListItemText-primary": {
            color: palette.text.primary,
            fontFamily: "Quantico",
            fontStyle: "normal ",
            fontWeight: "700",
            fontSize: "18px",
            lineHeight: "25px",
            letterSpacing: "0.01em",
            textTransform: "uppercase",
          },
          "& .MuiListItemText-root": {
            flex: "unset",
          },
          "& .MuiSvgIcon-root": {
            fontSize: "30px",
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: palette.color.darkBlue,
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: palette.color.primary,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          "&.MuiButtonBase-root.MuiTab-root": {
            width: "268px",
            height: "70px",
            backgroundColor: palette.color.mediumGray,
            fontFamily: "Quantico",
            fontStyle: "normal",
            fontWeight: "700",
            fontSize: "24px",
            lineHeight: "43px",

            color: palette.text.primary,
            opacity: 0.4,
          },
          "&.MuiTab-root.Mui-selected": {
            backgroundColor: palette.color.darkGray,
            color: palette.text.primary,
            opacity: 1,
          },
        },
      },
    },
  },
});

export default Light;
