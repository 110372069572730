import React from "react";
import Tooltip from "@mui/material/Tooltip";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { CopyToClipboard as ReactCopyToClipBoard } from "react-copy-to-clipboard";
import MobileTooltip from "./MobileTooltip";

const CopyToClipboard = ({
  label,
  value,
  showBorder = false,
  showIcon = true,
  overrideStyles,
}) => {
  const [tooltipMessage, setTooltipMessage] =
    React.useState("Copy to Clipboard");

  return (
    <React.Fragment>
      <Box sx={{ display: { md: "block", mobile: "none" } }}>
        {value && label ? (
          <ReactCopyToClipBoard
            text={value}
            onCopy={() => {
              setTooltipMessage("Copied!");
              setTimeout(() => setTooltipMessage("Copy to Clipboard"), 1000);
            }}
          >
            <Tooltip title={tooltipMessage} disableFocusListener arrow>
              <Chip
                sx={
                  overrideStyles
                    ? overrideStyles
                    : {
                      color: "rgba(0, 0, 0, 0.7)",
                      fontFamily: "Open Sans",
                      fontSize: "12px !important",
                      backgroundColor: "rgba(0, 0, 0, 0.04)",
                      width: "100%",
                      height: "30px",
                      p: "12px",
                      borderRadius: "30px",
                      "&:hover": {
                        transition: "all 0.3s ease-in-out",
                        backgroundColor: "rgba(0, 0, 0, 0.04)",
                        color: "rgba(0, 0, 0, 1)",
                      },
                    }
                }
                variant="contained"
                clickable
                icon={
                  showIcon && (
                    <ContentCopyIcon
                      sx={{
                        fontSize: "15px",
                        color: "rgba(0, 0, 0, 0.7) !important",
                        "&:hover": {
                          transition: "all 0.3s ease-in-out",
                          color: "rgba(0, 0, 0, 1) !important",
                        },
                      }}
                    />
                  )
                }
                label={label}
              />
            </Tooltip>
          </ReactCopyToClipBoard>
        ) : (
          <Skeleton variant={"rectangular"} height={"20px"} width={"136px"} />
        )}
      </Box>
      <Box sx={{ display: { md: "none", mobile: "block" } }}>
        <MobileTooltip title={"Copied!"}>
          {value && label ? (
            <ReactCopyToClipBoard text={value}>
              <Chip
                sx={
                  overrideStyles
                    ? overrideStyles
                    : {
                      color: "rgba(0, 0, 0, 0.7)",
                      borderRadius: "0px",
                      border: showBorder
                        ? "1px solid #ffffff30"
                        : "1px solid #ffffff00",
                      backgroundColor: "transparent",
                      "&:hover": {
                        transition: "all 0.2s ease",
                        border: "1px solid #ffffff30",
                      },
                    }
                }
                variant="contained"
                clickable
                icon={
                  showIcon && (
                    <ContentCopyIcon
                      sx={{ fontSize: "15px", color: "#000 !important" }}
                    />
                  )
                }
                label={label}
              />
            </ReactCopyToClipBoard>
          ) : (
            <Skeleton variant={"rectangular"} height={"20px"} width={"136px"} />
          )}
        </MobileTooltip>
      </Box>
    </React.Fragment>
  );
};

export default CopyToClipboard;
