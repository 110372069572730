import React from "react";
import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import { useSnackbar } from "notistack";
import { useFormData } from "../../../context";
import UI from "../../../../../@components/UI";
import { useTranslation } from "react-i18next";

const Button = ({ onSuccess, onError, collection }) => {
  const [{ isPending }] = usePayPalScriptReducer();
  const { enqueueSnackbar } = useSnackbar();
  const { data: formData } = useFormData();
  const { t } = useTranslation();
  return (
    <>
      {isPending && <UI.Busy.Indicator width={100} height={100} />}
      <PayPalButtons
        style={{
          layout: "horizontal",
          tagline: false,
          shape: "rect",
          color: "gold",
          height: 44,
        }}
        createOrder={(data, actions) => {
          return actions.order.create({
            purchase_units: [
              {
                amount: {
                  value: collection?.total_price_with_tax,
                },
                custom_id: JSON.stringify({
                  order_id: formData?.order_id,
                  nft_destination_address: formData?.nft_destination_address,
                }),
              },
            ],
          });
        }}
        onApprove={(data, actions) => {
          return actions.order.capture().then(function (details) {
            enqueueSnackbar(t("payment.fiat.snackbar"), {
              variant: "success",
            });
            onSuccess();
          });
        }}
        onCancel={() =>
          enqueueSnackbar(t("payment.fiat.snackbar2"), {
            variant: "error",
          })
        }
        onError={(err) => {
          enqueueSnackbar(t("payment.fiat.snackbar3"), {
            variant: "error",
          });
          onError();
        }}
      />
    </>
  );
};

export default Button;
