import React from "react";
import { Box, Paper } from "@mui/material";
import { Skeleton } from "@mui/lab";

const Header = ({ DAO }) => {
  const source = localStorage.getItem("source");
  const applyCustomStyles = source === "qvrse" ? false : true;
  return (
    <Box
      sx={{
        position: { mobile: "absolute", md: "relative" },
        width: "100%",
        bottom: 0,
        backgroundColor: applyCustomStyles
          ? DAO?.dao_style[0]?.data?.background_color
          : "#ffffff",
        height: "88px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        mb: "40px"
      }}
    >
      <Paper
        sx={{
          borderRadius: "50%",
          backgroundColor: "#F2F2F2",
          width: { mobile: "60px", md: "65px" },
          height: { mobile: "60px", md: "65px" },
          display: "flex",
          mt: { mobile: "0px", md: "30px" },
          mb: { mobile: "20px", md: "0px" },
          overflow: "hidden",
          boxShadow: "none",
          transition: "all 0.5s ease-in-out",
          border: "0px solid transparent",
          "&:hover": {
            border: "0px solid transparent",
            transform: "scale(1.1)",
          },
        }}
      >
        {DAO?.avatar_url ? (
          <Box
            component={"img"}
            sx={{
              width: "100%",
              height: "100%",
              margin: "0 auto",
              textAlign: "center",
              objectFit: "cover",
            }}
            src={DAO?.avatar_url}
            alt={"dao-logo"}
          />
        ) : (
          <Skeleton variant={"circle"} width={80} height={80} />
        )}
      </Paper>
    </Box>
  );
};

export default Header;
